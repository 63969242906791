
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { Property } from '../middleware/Property'

import PageLayout from '../components/common/PageLayout.vue'
import InstagramWidget from '../components/common/InstagramWidget.vue'
import TestimonialsCarousel from '../components/common/TestimonialsCarousel.vue'

import PropertyLeasePeriods from '../components/landing/LeasePeriods/PropertyLeasePeriods.vue'
import PropertyFriends from '../components/landing/PropertyFriends.vue'
import PropertyAbout from '../components/landing/PropertyAbout.vue'
import PropertyGallery from '../components/landing/PropertyGallery.vue'
import PropertyFeatures from '../components/landing/PropertyFeatures.vue'
import PropertyAdditionalResources from '../components/landing/PropertyAdditionalResources.vue'
import PropertyLocation from '../components/landing/PropertyLocation.vue'
import ContactForm from '../components/landing/ContactForm.vue'
import HowItWorks from '../components/landing/HowItWorks.vue'
import { authService } from '../services/authServiceInstance'

const namespace: string = 'property'

@Component({
  components: {
    PageLayout,
    InstagramWidget,
    PropertyLeasePeriods,
    PropertyFriends,
    PropertyAbout,
    TestimonialsCarousel,
    PropertyGallery,
    PropertyFeatures,
    PropertyAdditionalResources,
    PropertyLocation,
    ContactForm,
    HowItWorks,
  },
})

export default class PropertyPage extends Vue {
  @Prop({ required: true, type: [String, Number] }) id!: string;

  @Getter('singleProperty', { namespace }) singleProperty: (id: number | string) => Property;
  @Getter('propertyLoading', { namespace }) propertyLoading: any;

  @Action('loadSingleProperty', { namespace }) loadSingleProperty: any;
  @Action('toggleAuthModal', { namespace: 'ui' }) toggleAuthModal: any;
  @Watch('property')
  onPropertyLoad () {
    if (this.property.status === 'DISABLED') {
      this.$router.push({ name: 'Home' })
    }
    this.setTitle()
  }

  get property () {
    return this.singleProperty(this.id)
  }

  get fullAddress () {
    if (!this.property || !this.property.code) return ''
    return `${this.property.street}, ${this.property.city}, ${this.property.state} ${this.property.code}`
  }

  get propertyMedia () {
    return this.property ? this.property.background_media.sort((a, b) => a.order - b.order).map(media => media.file) : []
  }

  setTitle () {
    if (this.property) {
      document.title = 'Room Choice | ' + this.property.name
    }
  }

  checkAvailability () {
    if (authService.hasRole('student')) return

    this.toggleAuthModal({
      open: true,
      type: 'signup',
      data: {
        assigned_property: this.property.id,
      },
    })
  }

  async mounted () {
    if (!this.property || !this.property.full) {
      this.loadSingleProperty(this.id)
    }
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }
    window.scrollTo(0, 0)
  }

  get socials () {
    return this.property ? [
      { type: 'facebook', icon: 'facebook', link: this.property.facebook },
      { type: 'twitter', icon: 'twitter', link: this.property.twitter },
      { type: 'instagram', icon: 'instagram', link: this.property.instagram },
    ].filter(({ link }) => link) : []
  }

  getBlockVisibility (name: string) {
    if (!this.property) return false
    const attr = this.property.website_block_attrs.find(attr => attr.block_name === name)
    return attr ? attr.visibility : true
  }

  getBlockDescription (name: string) {
    if (!this.property) return
    const attr = this.property.website_block_attrs.find(attr => attr.block_name === name)
    return attr ? attr.description : ''
  }
}

const createQueryParamsString = (params: any) => Object.keys(params).map((key) => params[key] ? encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) : '').join('&')

// Taken from RC-manager. So some parts might be not functional.
export const API = {
  ACCOUNTS: {
    BASE: '/api/accounts/',
    SINGLE: (accountId: number) => `/api/accounts/${accountId}/`,

    CONFIRM: (accountId: number) => `/api/accounts/${accountId}/confirm/`,
  },

  AGREEMENT_PERIODS: {
    BASE: '/api/agreement-periods/',

    ADD_SERVICE: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/add_service/`,
    EDIT: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/edit/`,
    EDIT_GENDER: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/edit_gender/`,
    FAST: '/api/agreement-periods/?fast=true/',
    INVISIBLE_TO_MANAGERS: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/invisible_to_managers/`,
    INVISIBLE_TO_TENATS: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/invisible_to_tenants/`,
    NOT_RENEWALS_ONLY: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/not_renewals_only/`,
    PROPERTY: (propertyId: number | string) => `/api/agreement-periods/?property=${propertyId}`,
    PROPERTY_FAST: (propertyId: number | string) => `/api/agreement-periods/?property=${propertyId}&fast=true`,
    RENEWALS_ONLY: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/renewals_only/`,
    RESERVATIONS: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/reservations/`,
    RESERVATIONS_OUTPUT_CSV: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/reservations/?output=csv`,
    SERVICES: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/services/`,
    SERVICES_BY_BED: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/services_by_bed/`,
    SHOW_ALL: (propertyId?: number | string) => `/api/agreement-periods/?${createQueryParamsString({ show_all: true, property: propertyId })}`,
    TEST_GENDER_MAP: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/test_gender_map/`,
    VISIBLE_TO_MANAGERS: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/visible_to_managers/`,
    VISIBLE_TO_TENATS: (agreementPeriodId: number | string) => `/api/agreement-periods/${agreementPeriodId}/visible_to_tenants/`,
  },

  AGREEMENT_PERIODS_RESOURCE: {
    SINGLE: (agreementPeriodId: number | string) => `/api/agreement-period-resource/${agreementPeriodId}/`,
  },

  CARDS: {
    BASE: '/api/cards/',
    SINGLE: (cardId: number) => `/api/cards/${cardId}/`,
  },

  CONTRACT: {
    GUARANTOR_STEP: (inviteId: number) => `/api/contract/guarantor-step/?invite=${inviteId}`,
    SALES_FUNNEL: (reservationId: number) => `/api/contract/?sales_funnel=${reservationId}`,
  },

  DIRECT_SET_PASSWORD: (userEmail: string) => `/api/direct-set-password/${userEmail}/`,
  DIRECT_SET_EMAIL: '/api/direct-set-email/',

  EMAIL_SET_PASSWORD: {
    BASE: '/api/email-set-password/',
    WITH_INVITE_ID: (inviteId: number) => `/api/email-set-password/?invite_id=${inviteId}`,
  },

  INVITATIONS: {
    CLAIM: (token: string) => `/api/invitations/claim/?token=${token}`,
    CONFIRM: (token: string) => `/api/invitations/confirm/?token=${token}`,
    DECLINE: (token: string) => `/api/invitations/decline/?token=${token}`,
  },

  MESSAGES: {
    TENAT_TO_TENAT: '/api/messages/tenant_to_tenant/',
    TO_PROPERTY: '/api/messages/to_property/',
  },

  ORGANIZATIONS: {
    BASE: '/api/organizations/',

    MGR: '/api/organizations/?v=mgr',
    TOGGLE_MAINTENANCE: '/api/organizations/1/toggle_maintenance/',
    CONTACT: (id: string) => `/api/organizations/${id}/contact/`,
  },
  ORGANIZATION_BG_MEDIA: {
    BASE: '/api/organization-bg-media/',
    SINGLE: (id: string) => `/api/organization-bg-media/${id}/`,
    MOVE_TO: (id: string) => `/api/organization-bg-media/${id}/move_to/`,
  },
  AMENITY: {
    BASE: '/api/property-amenities/',
    SINGLE: (id: string) => `/api/property-amenities/${id}/`,
    MOVE_TO: (id: string | number) => `/api/property-amenities/${id}/move_to/`,
  },
  TESTIMONIALS: {
    BASE: (organizationId: string) => `/api/organizations/${organizationId}/testimonials/`,
    PROPERTY_TESTIMONIALS: '/api/property-testimonials/',
    SINGLE_PROPERTY_TESTIMONIAL: (id: string) => `/api/property-testimonials/${id}/`,
  },
  PROPERTY_FRIENDS: {
    BASE: '/api/property-friends',
  },
  PORTFOLIOS: {
    BASE: '/api/portfolios/',
    SINGLE: (id: string) => `/api/portfolios/${id}/`,
    EDIT: (id: string) => `/api/portfolios/${id}/edit/`,
    UPLOAD_IMAGE: (id: string) => `/api/portfolios/${id}/upload_image/`,
  },
  PAYMENT_SCHEDULES: {
    BASE: '/api/payment-schedules/',
    SET_DELETED: (scheduleID: number) => `/api/payment-schedules/${scheduleID}/set_deleted/`,
  },

  PERSONALITY_QUIZ: {
    GET_OR_CREATE_USER_ASSESSMENT: '/api/personality-quiz-service/get-or-create-user-assessment/',
    COMPLETE_QUIZ: '/api/personality-quiz-service/complete-quiz/',
    SKIP_QUIZ: '/api/personality-quiz-service/skip-quiz/',
  },
  PROPERTIES: {
    BASE: '/api/properties/',
    SINGLE: (propertyId: number | string) => `/api/properties/${propertyId}/`,
    UPDATE: (propertyId: number | string) => `/api/properties-update/${propertyId}/`,
    ADD_PERIOD: (propertyId: number | string) => `/api/properties/${propertyId}/add_period/`,
    ADD_REALTY: (propertyId: number | string) => `/api/properties/${propertyId}/add_realty/`,
    ADD_UNIT_TYPE: (propertyId: number | string) => `/api/properties/${propertyId}/add_unit_type/`,
    GET_ACCOUNTS: (propertyId: number | string) => `/api/properties/${propertyId}/get_accounts/`,
    GET_LATE_FEES: (propertyId: number | string) => `/api/properties/${propertyId}/get_late_fees/`,
    SET_ACTIVE_APPLICATION: (propertyId: number | string) => `/api/properties/${propertyId}/set_active_application/`,
    SET_CLOSE_DATE: (propertyId: number | string, closeDate: string) => `/api/properties/${propertyId}/set_close_date/?close_date=${closeDate}`,
    NEW_ACCOUNT: (propertyId: number | string) => `/api/properties/${propertyId}/new_account/`,
    TO_PROPERTY_MESSAGES: (propertyId: number | string) => `/api/properties/${propertyId}/to_property_messages/`,
    UNIT_TYPES: (propertyId: number | string) => `/api/properties/${propertyId}/unit_types/`,
    UNTYPED_UNITS: (propertyId: number | string) => `/api/properties/${propertyId}/untyped_units/`,
    UPLOAD_FLOOR_PLANS: (propertyId: number | string) => `/api/properties/${propertyId}/upload_floor_plans/`,
    UPLOAD_HEADER: (propertyId: number | string) => `/api/properties/${propertyId}/upload_header/`,
    UPLOAD_PROPERTY_IMAGES: (propertyId: number | string) => `/api/properties/${propertyId}/upload_property_images/`,
    UPDATE_PROPERTY_IMAGE: (id: number | string) => `/api/property-gallery-image/${id}`,
    DELETE_PROPERTY_IMAGE: (id: number | string) => `/api/property-gallery-image/${id}`,
    MOVE_TO_PROPERTY_IMAGE: (id: number | string) => `/api/property-gallery-image/${id}/move_to/`,
    VERIFICATION: (propertyId: number | string) => `/api/properties/${propertyId}/verification/`,
    CONTACT: (propertyId: number | string) => `/api/properties/${propertyId}/contact/`,
  },

  PROPERTY_BG_MEDIA: {
    BASE: '/api/property-bg-media/',
    SINGLE: (id: string) => `/api/property-bg-media/${id}/`,
    MOVE_TO: (id: string) => `/api/property-bg-media/${id}/move_to/`,
  },
  WEBSITE_BLOCK_ATTRS: {
    PROPERTY_BASE: '/api/property-website-block-attrs/',
    PROPERTY_SINGLE: (id: string) => `/api/property-website-block-attrs/${id}/`,
    ORGANIZATION_BASE: '/api/org-website-block-attrs/',
    ORGANIZATION_SINGLE: (id: string) => `/api/org-website-block-attrs/${id}/`,
  },
  PROPERTY_RESOURCES: {
    BASE: '/api/property-resources/',
    SINGLE: (id: string) => `/api/property-resources/${id}/`,
  },
  PROPERTIES_SETTINGS: (propertyId: number) => `api/properties-settings/${propertyId}/`,
  PROPERTIES_SITE: (propertyId: number) => `/api/properties-site/${propertyId}/`,
  PROPERTY_SETTINGS_RESOURCE: (propertyId: number) => `api/property-settings-resource/${propertyId}/`,

  REMIND_LEAD: '/api/remind-lead/',
  REMIND_USER: (userId: number, token: string) => `/api/remind-user/?user_id=${userId}&token=${token}`,

  REPORT: {
    CONFIRM_SERVICES: '/api/report/confirm_services/',
    NEW_REALTY: '/api/report/new_realty/',
    NEW_SERVICES: '/api/report/new_services/',
  },

  RESOURCES: {
    BASE: '/api/resources/',
    BELONGS_TO: (userId: number) => `/api/resources/?belongs_to=${userId}`,
  },

  SALES_FUNNELS: {
    ADD_FRIEND: (funnelId: number) => `/api/sales-funnels/${funnelId}/add_friend/`,
    ADD_CHARGE: (funnelId: number, chargeOptionId: number) => `/api/sales-funnels/${funnelId}/add_charge/?id=${chargeOptionId}`,
    APP: (funnelId: number) => `/api/sales-funnels/${funnelId}/app/`,
    CANCEL: (funnelId: number) => `/api/sales-funnels/${funnelId}/cancel/`,
    CONFIRM: (funnelId: number) => `/api/sales-funnels/${funnelId}/confirm/`,
    FETCH: (funnelId: number) => `/api/sales-funnels/${funnelId}/fetch/`,
    FILL_FORM: (funnelId: number) => `/api/sales-funnels/${funnelId}/fill_form/`,
    GET_GUARANTOR_FROM_INVITE: (inviteId: number) => `/api/sales-funnels/get_guarantor_from_invite/?invite=${inviteId}`,
    PAY_FIRST_NOW: {
      BASE: (funnelId: number) => `/api/sales-funnels/${funnelId}/pay_first_now/`,
      WITH_SIDEBAR: (funnelId: number) => `/api/sales-funnels/${funnelId}/pay_first_now/?sidebar=true`,
    },
    VUE_CHARGES: (funnelId: number) => `/api/sales-funnels/${funnelId}/vue_charges/`,
  },

  SEMESTERS: {
    BASE: '/api/semesters/',
    SINGLE: (semesterId: number | string) => `/api/semesters/${semesterId}/`,

    NEW: '/api/semesters',
    EDIT: (semesterId: number | string) => `/api/semesters/${semesterId}/edit/`,
  },

  SEND_RESET_EMAIL: '/api/send_reset_email/',

  SETTINGS_RESOURCE: '/api/settings-resource/',

  TICKETS: {
    CATEGORIES: '/api/tickets/categories/',
  },

  TOKEN_AUTH: '/api-token-auth/',

  USER_EMAIL_CONFIRMATION: '/api/user-email-confirmations/',

  USERS: {
    SINGLE: (userId: number) => `/api/users/${userId}/`,

    APP: (userId: number) => `/api/users/${userId}/app/`,
    AGREED_TO_TOS: (userId: number) => `/api/users/${userId}/agreed_to_tos/`,
    APP_APPLICATION: (userId: number) => `/api/users/${userId}/app/?fields=application`,
    APP_PERSONAL: (userId: number) => `/api/users/${userId}/app/?fields=personal`,
    ESTIMATE_FEES: (userId: number) => `/api/users/${userId}/estimate_fees/`,
    FILL_FORM: (userId: number) => `/api/users/${userId}/fill_form/`,
    GET_ACCOUNTS: (userId: number) => `/api/users/${userId}/get_accounts/`,
    GET_CARDS: (userId: number) => `/api/users/${userId}/get_cards/`,
    GET_RELATED_USERS: (userId: number) => `/api/users/${userId}/get_related_users/`,
    INBOX: (userId: number) => `/api/users/${userId}/inbox/`,
    INVITATIONS: (userId: number) => `/api/users/${userId}/invitations/`,
    LEDGERS: (userId: number) => `/api/users/${userId}/ledgers/`,
    ME: '/api/users/me/',
    MY_TICKETS: (userId: number) => `/api/users/${userId}/my_tickets/`,
    NEW_RESERVATION: (userId: number) => `/api/users/${userId}/new_reservation/`,
    NEW_TICKET: (userId: number) => `/api/users/${userId}/new_ticket/`,
    SENT_MESSAGES: (userId: number) => `/api/users/${userId}/sent_messages/`,
    SHOW_PUBLIC_PROFILE: (userId: number, showValue: boolean) => `/api/users/${userId}/show_public_profile/?set=${showValue}`,
    SEND_INVITATION: '/api/users/send_invitation/',
    SIGN_UP: '/api/users/sign_up/',
    TENAT_PAYMENT: (userId: number) => `/api/users/${userId}/tenant_payment/`,
    UNITS: (userId: number) => `/api/users/${userId}/units/`,
  },

  VUE_SALES_FUNNEL: {
    UPDATE_CONTRACT: (funnelId: number) => `/api/vue-sales-funnel/${funnelId}/update_contract/`,
    UPDATE_QUIZ: (funnelId: number) => `/api/vue-sales-funnel/${funnelId}/update_quiz/`,
    UPDATE_PAYMENT: (funnelId: number) => `/api/vue-sales-funnel/${funnelId}/update_payment/`,
  },

  VUE_USER_SALES_FUNNELS: {
    GET_SALES_FUNNELS: (userId: number) => `/api/vue-user-sales-funnels/${userId}/get_sales_funnels/`,
  },

}

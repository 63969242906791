
import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import LoginForm from './LoginForm.vue'
import SignupForm from './SignupForm.vue'
import RegisterForm from './RegisterForm.vue'
import ResetPasswordForm from './ResetPasswordForm.vue'

// TODO Requires rewrite. Everything is bound too tightly.
@Component({
  components: {
    LoginForm,
    SignupForm,
    RegisterForm,
    ResetPasswordForm,
  },
})
export default class AuthModal extends Vue {
  @Getter('authModal', { namespace: 'ui' }) authModal: any;
  @Action('toggleAuthModal', { namespace: 'ui' }) toggleAuthModal: any;

  get currentComponent () {
    const type = this.authModal.type
    return type.charAt(0).toUpperCase() + type.slice(1) + 'Form'
  }

  changeModalType (type) {
    this.toggleAuthModal({
      type,
    })
  }

  closeModal () {
    this.toggleAuthModal({
      open: false,
    })
  }
}


  import { Vue, Component, Prop } from 'vue-property-decorator'
  import validationRules from '../../validation/validationRules'
  
  @Component({})
  export default class SignupForm extends Vue {
    infoText = '';
    errorText = [];
    email = '';
    rules = {
      required: v => validationRules.checkRequired(v) || '',
      isEmail: v => validationRules.checkEmail(v) || '',
    };
  
    get isValid (): boolean {
      return (this.$refs.form as Vue & { validate: () => boolean }).validate()
    }
  
    async sendForm () {
      if (!this.isValid) return false
  
      try {
        this.infoText = (await this.$api.auth.sendInvitation({ email: this.email })).detail
        setTimeout(() => {
          this.infoText = ''
          this.$emit('sent')
        }, 3000)
      } catch (data) {
        this.errorText = []
        if (data) {
          for (const key in data) {
            this.errorText.push(data[key][0])
          }
        }
      }
    }
  
    sendInvitation () {
      return this.$api.auth.sendInvitation({ email: this.email })
    }
  }
  
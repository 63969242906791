
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { Property } from '../../../middleware/Property'
import { Swiper as VueSwiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import { authService } from '../../../services/authServiceInstance'
import { LeasePeriod } from '../../../middleware/LeasePeriod'
import moment from 'moment'
import LeasePeriodListItem from './LeasePeriodListItem.vue'
import LeasePeriodsFilters from './LeasePeriodsFilters.vue'
import { LeasePeriodFilters } from './LeasePreiodTypes'
import { LeasePeriodSemester } from '../../../middleware/LeasePeriodSemester'

// @deprecated
@Component({
  components: {
    LeasePeriodsFilters,
    LeasePeriodListItem,
    VueSwiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
})
export default class PropertyLeasePeriods extends Vue {
  @Prop({ type: Property }) property: Property;
  @Prop({ type: String }) description: string;
  @Prop({ type: Boolean }) previewModeOnly: boolean;
  @Action('toggleAuthModal', { namespace: 'ui' }) toggleAuthModal: any;

  @Ref() vueSwiper: any // really VueSwiper, but gives TS error

  leasePeriods: LeasePeriod[] = []
  currentSlide = 1;
  selectedPeriod = null;
  selectedRoomType = null;

  filters = {
    semesterId: null,
    gender: null,
    roomType: null,
    sortBy: 'asc',
  } as LeasePeriodFilters

  get leasePeriodsFiltered () {
    let result = this.leasePeriods
    if (this.filters.semesterId !== null) {
      result = result.filter(leasePeriod => leasePeriod.semesters.some(semester => semester.id === this.filters.semesterId))
    }
    if (this.filters.gender !== null) {
      result = result.filter(leasePeriod => leasePeriod.genders_available.includes(this.filters.gender))
    }
    if (this.filters.roomType !== null) {
      // TODO
      // result.filter(leasePeriod => leasePeriod.genders_available.includes(this.filters.gender))
    }
    if (this.filters.sortBy !== null) {
      result.sort((a, b) => new Date(a.start_date) > new Date(b.start_date) ? 1 : -1)
      if (this.filters.sortBy === 'asc') {
        result.reverse()
      }
    }
    console.log(result)
    return result
  }

  get semesters () {
    const semesterById: Record<string, LeasePeriodSemester> = {}
    this.leasePeriods.forEach(leasePeriod => {
      leasePeriod.semesters.forEach(semester => {
        semesterById[semester.id] = semester
      })
    })
    return Object.values(semesterById)
  }

  checkAvailability (leasePeriodId: number) {
    if (authService.hasRole('student')) return

    const leasePeriod = this.leasePeriods.find(leasePeriod => leasePeriod.id === leasePeriodId)

    if (leasePeriod.open_to_renewals || leasePeriod.allow_same_bed_renewals) {
      this.toggleAuthModal({
        open: true,
        type: 'login',
        data: {},
      })
    } else {
      this.toggleAuthModal({
        open: true,
        type: 'signup',
        data: {
          agreement_period: leasePeriodId,
          start_date: leasePeriod.start_date,
          end_date: leasePeriod.end_date,
        },
      })
    }
  }

  async mounted () {
    // await this.$api.semester.get()

    try {
      const leasePeriods = await this.$api.leasePeriod.getForProperty(this.property.id)

      this.leasePeriods = leasePeriods
        .filter(({ visible }) => visible)
    } catch (e) {
      await this.$store.dispatch('ui/showError', "Couldn't load lease periods")
    }
  }
}

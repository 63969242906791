
import { Component, Vue } from 'vue-property-decorator'
import LoginForm from './LoginForm.vue'
import ResetPasswordForm from './ResetPasswordForm.vue'
import RegisterForm from './RegisterForm.vue'
import { AuthFormTab } from './AuthForm'
import SignupForm from './SignupForm.vue'
import { VueConstructor } from 'vue'

@Component({
})
export default class AuthForm extends Vue {
  tab: AuthFormTab = this.get_tab()

  get currentComponent () {
    const typeToComponent: Record<AuthFormTab, VueConstructor> = {
      login: LoginForm,
      register: RegisterForm,
      signup: SignupForm,
      resetPassword: ResetPasswordForm,
    }
    return typeToComponent[this.tab]
  }

  get_tab () {
    if (window.location.pathname === '/new/register') {
      return 'register'
    }
    return 'login'
  }
}

import { Property } from './Property'
import { AccountCode } from './AccountCode'
// TODO Split into multiple files.

export class Organization {
  constructor (data?: Partial<Organization>) {
    if (data && data.properties) {
      data.properties = data.properties.map((rawProperty: Partial<Property>) => {
        return rawProperty instanceof Property ? rawProperty : new Property(rawProperty)
      })
    }

    if (data && data.account_codes) {
      data.account_codes = data.account_codes.map((rawAccountCode: Partial<AccountCode>) => {
        return rawAccountCode instanceof AccountCode ? rawAccountCode : new AccountCode(rawAccountCode)
      })
    }

    Object.assign(this, data)
  }

  id: number = NaN;
  description: string = '';
  tagline: string = '';
  properties: Property[] = [];
  account_codes: AccountCode[] = [];
  background_media: { type: string, src: string }[] = [];
  website_block_attrs: { name: string, visibility: boolean }[] = [];
}
export class User {
  constructor (data?: Partial<User>) {
    Object.assign(this, data)
  }

  assigned_property: number = NaN;
  cell_phone: number = NaN;
  city: string = '';
  date_joined: string = '';
  default_from: string = '';
  email: string = '';
  email_signature: string = '';
  first_name: string = '';
  gender: string = '';
  home_phone: string = '';
  id: number = NaN;
  image: string = '';
  invite_id: number = NaN;
  invited_by: string = '';
  invited_users: string[] = [];
  is_active: boolean = false;
  is_confirmed: boolean = false;
  is_excluded_from_late_fees: boolean = false;
  is_staff: boolean = false;
  is_quiz_skipped: boolean = false;
  last_name: string = '';
  last_login: string = '';
  postcode: string = '';
  primary_bank_account: string = '';
  primary_card: string = '';
  roles: string[] = [];
  state: string = '';
  street: string = '';
  traitify_assessment_id: null
  ledger_access: string = '';
  name: string = '';
  move_in: string = '';
  move_out: string = '';
  ledger_balance: number = NaN;
}

export class SubPeriod {
  constructor (data?: Partial<SubPeriod>) {
    Object.assign(this, data)
  }

  id: number = NaN
  name: string = ''
  lease_period_id: number = NaN // inheritance binding
}

export class Layout {
  constructor (data?: Partial<Layout>) {
    Object.assign(this, data)
  }

  id: number = NaN
  property_id: number = NaN // inheritance binding
  building: string = ''
  floor: number = NaN
  unit: string = ''
  unit_type: string = ''
  room: string = ''
  room_type: string = ''
  bed: string = ''
}

export class PaymentPlanAssignment {
  constructor (data?: Partial<PaymentPlanAssignment>) {
    Object.assign(this, data)
  }

  id: number = NaN
  property_id: number = NaN // inheritance binding (should probably be bound through a Layout)
  building: string = ''
  floor: number = NaN
  unit: string = ''
  unit_type: string = ''
  room: number = NaN
  room_type: string = ''
  bed: string = ''
  new_tenant_payment_plan_ids: number[] = []
  renewal_tenant_payment_plan_ids: number[] = []
  renewal_transfer_tenant_payment_plan_ids: number[] = []
}

export class DocumentTemplate {
  constructor (data?: Partial<DocumentTemplate>) {
    Object.assign(this, data)
  }

  static get types () {
    return ['Lease Agreement', 'Email Template', 'SMS Template']
  }

  id: number = NaN
  name: string = ''
  type: string = ''
  created_at: string = ''
  approved?: boolean = false
  template: {
    subject?: any[],
    body: any[]
  } = { body: [] };

  approved_by: string | null = '';
  approved_at: string | null = '';
  created_by: string = '';
}

export class RentalApplication {
  constructor (data?: Partial<RentalApplication>) {
    Object.assign(this, data)
  }

  id: number = NaN
  organization_id: number = NaN // inheritance binding

  // ;[key: string]: boolean | number // temp - remove when mocking is off

  // naming should probably be refined...

  // pi - personal information
  pi_name: boolean = false
  pi_middle_name: boolean = false
  pi_date_of_birth: boolean = false
  pi_phone: boolean = false
  pi_email: boolean = false
  pi_is_us_citizen: boolean = false
  pi_social_security_number: boolean = false
  pi_social_security_card_copy: boolean = false
  pi_passport_or_visa_number: boolean = false
  pi_passport_or_visa_copy: boolean = false
  pi_is_passport_or_visa_ok: boolean = false
  pi_bad_passport_or_visa_explanation: boolean = false

  // bio - profile photo & bio
  bio_profile_photo: boolean = false
  bio_bio: boolean = false

  // ss - student status
  ss_is_full_time_student: boolean = false
  ss_school: boolean = false
  ss_school_issued_email: boolean = false
  ss_school_issued_id: boolean = false

  // pets
  pets_is_any: boolean = false
  pets_name: boolean = false
  pets_age: boolean = false
  pets_type: boolean = false
  pets_breed: boolean = false
  pets_weight: boolean = false
  pets_training: boolean = false
  pets_photo: boolean = false

  // ca - current address
  ca_current_address: boolean = false
  ca_is_rented_or_owned: boolean = false
  ca_type: boolean = false
  ca_moving_reason: boolean = false
  ca_move_in_date: boolean = false
  ca_total_monthly_rent: boolean = false
  ca_payed_monthly_rent: boolean = false
  ca_landlord_name: boolean = false
  ca_landlord_email: boolean = false
  ca_landlord_phone_number: boolean = false
  ca_is_landlord_contactable: boolean = false
  ca_additional_info: boolean = false

  // pa - previous address
  pa_previous_address: boolean = false
  pa_is_rented_or_owned: boolean = false
  pa_type: boolean = false
  pa_moving_reason: boolean = false
  pa_move_in_date: boolean = false
  pa_total_monthly_rent: boolean = false
  pa_payed_monthly_rent: boolean = false
  pa_landlord_name: boolean = false
  pa_landlord_email: boolean = false
  pa_landlord_phone_number: boolean = false
  pa_is_landlord_contactable: boolean = false
  pa_additional_info: boolean = false

  // th - tenant history
  th_ever_evicted: boolean = false
  th_eviction_explanation: boolean = false
  th_ever_refused_to_pay: boolean = false
  th_refusing_explanation: boolean = false
  th_ever_damaged_property: boolean = false
  th_damage_explanation: boolean = false
  th_ever_convicted: boolean = false
  th_conviction_explanation: boolean = false
  th_will_request_accommodations: boolean = false
  th_accommodations_explanation: boolean = false

  // fi - financial information
  fi_money_for_rent_source: boolean = false
  fi_income_source: boolean = false

  // pfi - previous financial information
  pfi_income_source: boolean = false

  // ai - additional information
  ai_health_insurance: boolean = false
  ai_health_insurance_plan: boolean = false
  ai_ever_declared_bankruptcy: boolean = false
  ai_bankruptcy_explanation: boolean = false
  ai_is_smoker: boolean = false
  ai_smoking_explanation: boolean = false

  // t - transportation
  t_any_vehicle: boolean = false
  t_type: boolean = false
  t_make: boolean = false
  t_model: boolean = false
  t_year: boolean = false
  t_monthly_payments: boolean = false
  t_way_to_get_to_work_or_school: boolean = false

  // d - documents and proof of identity
  d_proof_file: boolean = false
  d_proof_type: boolean = false

  // smp - social media profiles
  smp_facebook: boolean = false
  smp_youtube: boolean = false
  smp_whatsapp: boolean = false
  smp_instagram: boolean = false
  smp_twitter: boolean = false
  smp_linkedin: boolean = false
  smp_snapchat: boolean = false
  smp_pinterest: boolean = false
  smp_wechat: boolean = false
  smp_tumblr: boolean = false

  // lg - lease guarantor / guaranty
  lg_personalize_invitation: boolean = false
  lg_name: boolean = false
  lg_phone: boolean = false
  lg_email: boolean = false

  // rm - roommate matching public profile
  rm_age: boolean = false
  rm_gender: boolean = false
  rm_animal_allergies: boolean = false
  rm_field_of_study: boolean = false
  rm_class_level: boolean = false
  rm_clubs: boolean = false
  rm_school_or_college_or_university: boolean = false
  rm_frequent_guests: boolean = false
  rm_partying: boolean = false
  rm_loud_music: boolean = false
  rm_cooking: boolean = false
  rm_smoking: boolean = false
  rm_car: boolean = false
  rm_cleanliness: boolean = false
  rm_prefer_to_study_at: boolean = false
  rm_pet_friendly: boolean = false
  rm_usual_sleep_time: boolean = false
  rm_usual_wake_time: boolean = false
  rm_additional_info: boolean = false

  // ec - emergency contact information
  ec_emergency_contact: boolean = false
  ec_relationship: boolean = false
  ec_email: boolean = false
  ec_phone: boolean = false

  // ari - arrival information
  ari_datetime: boolean = false
  ari_flight_number: boolean = false
  ari_is_airport_shuttle_needed: boolean = false
}

export class PropertySite {
  constructor (data?: Partial< PropertySite>) {
    Object.assign(this, data)
  }

  id: number = NaN;
  name: string = '';
  street: string = '';
  city: string = '';
  state: string = '';
  code: string = '';
  background_image: string = '';
  logo: string = '';
  property_images: Image[] = [];
  floor_plan_images: Image[] = [];
  property_url: string = '';
  phone_number: string = '';
  send_email_from: string = '';
  office_hours: string = '';
  community_amenities: string = '';
  apartment_amenities: string = '';
  detailed_descriptions: string = '';
  area_schools: School[] = [];
  overview: string = '';
  manager_email: string = '';
  manager_fname: string = '';
  manager_lname: string = '';
  organization: number = NaN;
  organization_name: string = '';
  email_signature: string = '';
  general_info: string = '';
  management_info: string = '';
}

export class PropertySettings {
  constructor (data?: Partial<PropertySettings>) {
    Object.assign(this, data)
  }

  id: number = NaN;
  name: string = '';
  use_unit_types: boolean = false;
  allow_custom_dates: boolean = false;
  owner: number = NaN;
  send_email_from: string = '';
  organization: number = NaN;
  organization_name: string = '';
  guarantor_form: string = '';
  application: number = NaN;
  show_reports: boolean = false;
  allow_send_emails: boolean = false;
  term_and_conditions_auto_payment: string = '';
  is_background_check: boolean = false;
  enable_roommate_matching_quiz: boolean = false;
  require_roommate_matching_quiz: boolean = false;
  enable_payment_in_full_option: boolean = false;
  guarantor_document: string = '';
  max_monthly_late_fee_type: string = '';
  max_monthly_late_fee: string = '';
  currency: string = '';
  enable_verification_reports_student: boolean = false;
  enable_verification_reports_guarantor: boolean = false;
  enable_timeline: boolean = false;
  enable_transfers: boolean = false;
  disable_payments: boolean = false;
  disable_maintenance: boolean = false;
}

export class AgreementPeriod {
  constructor (data?: Partial<AgreementPeriod>) {
    Object.assign(this, data)
  }

  id: number = NaN;
  name: string = '';
  property: number = NaN;
  property_name: boolean = false;
  visible: boolean = false;
  is_for_custom_lease: boolean = false;
  start_date: string = '';
  end_date: string = '';
  open_to_renewals: boolean = false;
  allow_same_bed_renewals: boolean = false;
  periods_to_renew_from: string[] = [];
  semesters: Semester[] = [];
  originally_visible: boolean = false;
  genders_available: string[] = [];
}

export interface School {
  name: string;
  url: string;
}

export interface Image {
  id: number;
  image: string;
  order?: number;
}

export interface Semester {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  icon: string;
  is_for_custom_lease: boolean
}
